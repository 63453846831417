<template>
  <div class="login-wrap">
    <div class="card-info">
      <!--            <div class="card-title">-->
      <!--                <img class="card-img" src="~@/assets/banner.png" alt="">-->
      <!--                <h1>AI中心统一鉴权服务</h1>-->
      <!--            </div>-->
      <h1>AI中心统一鉴权服务</h1>
      <div id="login_container"></div>
    </div>
    <div class="footer">
      <p>技术支持：AI&产品中心 - AI开发部（V20241216）</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AccountRegister',

  methods: {
    showWx() {
      const obj = new WwLogin({
        id: 'login_container',
        // login_type: 'CorpApp',
        appid: 'wwfcadd052f0b2dde5',
        agentid: '1000028',
        redirect_uri: `https://aiauth.biyao.com/login`,
        state: decodeURIComponent(window.location.search.substring(14)),
        lang: 'zh',
        self_redirect: false
      });
      console.log("response info:", obj)
    },
    getRedirectUri() {
      // encodeURIComponent(window.location.href)
      // redirect_uri
      return decodeURIComponent(window.location.search.substring(14))
    },
    getCookie(cookieName) {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        console.log(cookie)
        if (cookie.indexOf(cookieName + '=') === 0) {
          return true;
        }
      }
      return false;
    },
    verifyUrl(url) {
      const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
      return urlPattern.test(url);
    }
  },
  data() {
    return {
      redirectURI: null,
    };
  },
  mounted() {
    // const xKeyCookie = this.getCookie('x-ai-sk')
    // console.log(xKeyCookie)
    // this.redirectURI = btoa(window.location.href);
    // console.log("url", window.location.href)
    this.showWx();
    // if (!this.verifyUrl(this.redirectURI)) {
    //     this.redirectURI = "http://ai.biyao.com"
    // }
    // if (xKeyCookie && this.redirectURI) {
    //     // 重定向
    //     console.log('x-ai-sk cookie 存在，重定向', xKeyCookie);
    //     window.location = this.redirectURI;
    // } else {
    //     console.log('cookie不存在需要登录，需要登录');
    //     this.showWx()
    // }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-wrap {
  background-image: url('~@/assets/login-bg.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* 隐藏溢出内容，防止滚动条出
     /*display: flex;*/
  /*justify-content: center;*/
  /*align-content: center;*/
  /*height: 100vh; !* 视口高度的 100% *!*/
  /*margin: 0;*/

}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h1 {
  color: rebeccapurple;
}

.card-title {
  display: flex;
}

.card-img {
  width: 190px;
  height: 80px;
}

.footer {
  position: fixed;
  bottom: 0;
  font-size: 13px;
  left: 0;
  width: 100%;
  color: #737c84;
  padding: 10px;
  text-align: center;
}
</style>
