<template>
  <div id="app">
    <AccountRegister/>
  </div>
</template>

<script>
import AccountRegister from './components/AccountRegister.vue'

export default {
  name: 'App',
  components: {
    AccountRegister
  }
}
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
}
</style>
